import { searchConvertObj } from '../utils/utilHelper';
let host;
let processHost;
let baseUrl;
let searchInfo = window.location.pathname;
let cloud = '';
let brand = 0;
if (window.location.origin.indexOf('lj2') > -1 &&
  window.location.pathname.indexOf('fw') < 0) {
  let obj = searchConvertObj(window.location.search);
  cloud = obj.c;
  brand = obj.b;
}

if (process.env.NODE_ENV === 'development') {

  host = 'http://192.168.1.91:6433';
  processHost = 'https://xunjia.lijuyun.cn/api';
}
else {
  // if (window.location.origin.indexOf('lj2') > 0) {
  // 'https://api.lijuyun.cn/redirect/erp'
  // host = 'http://47.96.188.225:5500';
  host = 'https://www.lijusoft.com';
  // host = 'http://192.168.1.77:5500';
  baseUrl = '';
  switch (brand) {
    case '49':
      processHost = 'https://xunjia.lijuyun.cn/api';
      break;
    default:
      processHost = '';
  }
  // } else {
  //   host = `${window.location.origin}/api`;
  //   baseUrl = `${window.location.origin}/resource/`;
  // }

}

export { host, baseUrl, cloud, processHost };