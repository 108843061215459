import React from 'react';
import { Router, Redirect, Switch, Route, Routes, IndexRoute } from 'dva/router';
import Order from '../routes/order/order';
import Fill from '../routes/fill/fill';
import Result from '../routes/result/result';
import Process from '../routes/process/process';
import { renderRouteConfig } from '../utils/createRoot';

const menuGlobal = [
  {
    path: '/',
    component: Order,
    childRoutes: [
      {
        path: '/fw',
        name: '填写防伪码',
        component: Fill,
        childRoutes: [
          {
            path: '/fw/code',
            name: '查询结果',
            component: Result,
          }
        ]
      },
      {
        path: '/process',
        name: '查询进度',
        component: Process,
        childRoutes: [
          {
            path: '/process/result',
            name: '进度',
            component: Result,
          }
        ]
      },

    ]
  }
];

export function RouterConfig({ history, app }) {
  // let index = window.location.origin.indexOf('jinchan');
  let route = renderRouteConfig(menuGlobal, '/');
  return (
    <Router history={history} basename={'/'}>
      <Switch>
        <Route exact path="/fw" component={Fill} />
        <Route exact path="/fw/code" component={Result} />
        <Route exact path="/pr" component={Process} />
        <Route path="/" component={Order} />
        {/* <Route path='/' render={
          () => {
            return route;
          }
        } /> */}
      </Switch>
    </Router>
  );
}