import React, { Component } from 'react';
import styles from './app.module.css';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Image, Input, message } from 'antd';
import { getProcess, getCourier } from '../../services/order';
import { searchConvertObj } from '../../utils/utilHelper';
import moment from 'moment';

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      brand: 0,
      dataSource: [],
      posts: [],
      mode: 'input',
    };
  }
  componentDidMount() {
    let obj = searchConvertObj(window.location.search);
    this.setState({
      brand: obj.b,
      screenHeight: window.innerHeight,
    });
    // this.toUrl();
  }
  render() {
    const { code, brand, screenHeight, mode, dataSource, posts } = this.state;
    return (
      <div>
        {
          mode === 'input' &&
          <div className='col-sb w100 h100' style={{ height: screenHeight }}>
            <div className='col-t w100'>
              <Image src={`https://ljkj-web.oss-cn-hangzhou.aliyuncs.com/query-order/AntiFake/fw-${brand}-process.jpg`} preview={false} className='margin-b10' />
              <div className={`${styles['method']} col vertical-lt w100 margin-b10`}>
                <Input value={code} onChange={this.changeInput} placeholder='请输入您的订单号 ！' className={`${styles['input']} fz16`} />
                <div className='row w100'>
                  <Button onClick={this.toUrl} className={`${styles['btn']} margin-r10`}>查询</Button>
                  <Button onClick={this.empty} className={`${styles['btn']}`}>清空</Button>
                </div>
              </div>
            </div>
            <div className={`${styles['foot']} tc w100 fz16`}>进度查询中</div>
          </div>
        }
        {
          mode === 'table' &&
          <div className='col-t w100 h100'>
            <div className='w100 row-sb padding-t10 bold fz20 margin-b20'>
              <div className='opacity0 ww25'>加工进度</div>
              <div className='ww25'>加工进度</div>
              <div onClick={this.changeMode} className='ww25 tr padding-r10'>返回</div>
            </div>
            {
              dataSource.map((item, index) => {
                return (
                  <div className={`w100 padding-lr5 lin ${styles['process-line']}`} key={index}>
                    <div className='w100 row-sb bold fz15'>
                      <div className='ww20'>{item.room}</div>
                      <div className='ww50 tc'>{item.name}</div>
                      <div className='row ww25'>
                        <div>加工进度</div>
                        <div onClick={this.openLine.bind(this, index)} className='color5 fz16'>
                          {
                            item.open ?
                              <CaretDownOutlined />
                              :
                              <CaretRightOutlined />
                          }
                        </div>
                      </div>
                    </div>
                    <div className='w100'>
                      {
                        item.open &&
                        item.info.map((ditem, dindex) => {
                          return (
                            <div className={`row-sb vertical-lt w100 padding-lr5 lin ${styles['process-line2']}`} key={dindex}>
                              <div className='ww20 tl'>{ditem.process}</div>
                              <div className='ww50 tc'>{moment(ditem.timeOperate).format('YYYY/MM/DD/HH:mm:ss')}</div>
                              <div className='ww25 tc'>{ditem.workers[0] ? ditem.workers[0] : ''}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                );
              })
            }
            <div className='w100'>
              <div className='w100 padding-t20 tc bold fz20 margin-b20'>快递信息</div>
              {
                posts.map((item, index) => {
                  return (
                    <div className='col vertical-lt w100' key={index}>

                      <div className='fz16 bold padding-l10'>{item.company}</div>
                      {
                        item.numbers.map((nitem, nindex) => {
                          return (
                            <div className='w100 col-t vertical-lt' key={nindex}>
                              <div className='row-r w100 row-r padding-tb5'>
                                <div className='fz15 bold'>{nitem}</div>
                                <div onClick={this.openTrace.bind(this, index, nindex)} className='color5 bold fz17 margin-r20'>
                                  {
                                    posts[index].trace[nindex].open ?
                                      <CaretDownOutlined />
                                      :
                                      <CaretRightOutlined />
                                  }
                                </div>
                              </div>
                              {
                                posts[index].trace[nindex].open &&
                                posts[index].trace[nindex].records.map((titem, tindex) => {
                                  return (
                                    <div className='w100 row-sa color5 lin2' key={tindex} >
                                      <div className='ww25'>{titem.time}</div>
                                      <div className='ww70'>{titem.trace}</div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          );
                        })
                      }
                      {/* <div>{item.numbers}</div> */}
                    </div>
                  );
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
  changeInput = (e) => {
    this.setState({
      code: e.target.value
    });
  }
  empty = () => {
    this.setState({
      code: ''
    });
  }
  openLine = (index) => {
    let dataSource = [...this.state.dataSource];
    dataSource[index].open = dataSource[index].open ? false : true;
    dataSource[index].info = dataSource[index].info.slice(-3,);
    this.setState({
      dataSource
    });
  }
  changeMode = () => {
    this.setState({
      mode: 'input',
      dataSource: [],
      posts: [],
    });
  }
  toUrl = async () => {
    const { code, brand } = this.state;
    if (!code) {
      message.error('订单号不能为空');
      return;
    }
    // 4242406788533863648
    // let res = await getProcess('4242406788533863648');
    let res = await getProcess(code);
    if (res.status === 200) {
      let posts = res.data.posts;
      posts.map(item => {
        item.trace = [];
        item.numbers.map(nitem => {
          item.trace.push({
            open: false,
            records: [],
          });
        });
      });
      this.setState({
        dataSource: res.data.order,
        posts,
        mode: 'table'
      });
    }
  }
  openTrace = async (index, nindex) => {
    let posts = [...this.state.posts];
    if (!posts[index].trace[nindex].open && posts[index].trace[nindex].records.length === 0) {
      // order, companyId, number
      let res = await getCourier(posts[index].order, posts[index].companyId, posts[index].numbers[nindex]);
      if (res.status === 200) {
        posts[index].trace[nindex].records = res.data;
      }
    }
    posts[index].trace[nindex].open = posts[index].trace[nindex].open ? false : true;
    this.setState({
      posts
    });
  }
}
export default Process;