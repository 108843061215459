import request from '../utils/request';
import { processHost } from '../config/baseurl';

export async function getOrder(id) {
  return await request(`redirect/erp/inner/${id}/price/list/v2`, 'get');
}


export async function getUrl(brand, code, redirect) {
  return await request(`open/weixin/unique?unique=${brand}&code=${code}&redirect=${redirect}`, 'get');
}
export async function getResult(data) {
  return await request('open/weixin/unique', 'post', data);
}
export async function getRecords(data) {
  return await request('open/weixin/unique', 'put', data);
}
export async function getProcess(tid) {
  return await request(`${processHost}/open/tid/${tid}`, 'get');
}
export async function getCourier(order, companyId, number) {
  return await request(`${processHost}/open/post/list?order=${order}&company=${companyId}&number=${number}`, 'get');
}

